const title = "ubuntuevents";
const description = "Events & technology company";

const favicon = require("../assets/seo/favicon.ico");
const image = require("../assets/seo/twitter_image.jpg");
const favicon_16x16 = require("../assets/seo/favicon-16x16.png");
const favicon_32x32 = require("../assets/seo/favicon-32x32.png");

export const helmet = {
  title,
  titleTemplate: "%s | ubuntuevents",
  htmlAttributes: { lang: "en" },
  meta: [
    { name: "description", content: description },
    { name: "viewport", content: "width=device-width, initial-scale=1" },
    { name: "apple-mobile-web-app-status-bar-style", content: "black" },
    { name: "msapplication-navbutton-color", content: "#000" },
    { name: "msapplication-TileColor", content: "#00aba9" },
    { name: "theme-color", content: "#000" },
    { name: "icon", type: "image/png", sizes: "32x32", content: favicon_32x32 },
    { name: "icon", type: "image/png", sizes: "16x16", content: favicon_16x16 },

    { property: "og:title", content: title },
    { property: "og:image", content: image },
    { property: "og:image:width", content: "880px" },
    { property: "og:image:height", content: "440px" },
    { property: "og:image:alt", content: description },

    { name: "twitter:title", content: title },
    { name: "twitter:card", content: image },
    { name: "twitter:image", content: image },
    { name: "twitter:site", content: "@Ubuntu_Events" },
    { name: "twitter:creator", content: "@Ubuntu_Events" },
    { name: "twitter:description", content: description }
  ],
  link: [{ rel: "icon", type: "image/x-icon", href: favicon }]
};
