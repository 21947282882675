/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onInitialClientRender = () => {
  if (document) {
    document.body.classList.add("nosscroll");
  }
  setTimeout(function() {
    if (document) {
      document.body.classList.remove("nosscroll");
      const loader = document.getElementById("___loader");
      if (loader) {
        loader.remove();
      }
    }
  }, 1500);
};
