import React from "react";
// import anime from "animejs";
import { Menu } from "../header/menu";
import {
  TransitionGroup,
  Transition as ReactTransition
} from "react-transition-group";
import "./transitions.scss";

import * as utils from "../utils";

const timeout = 1000;
const getTransitionStyles = {
  entering: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 1
  },
  entered: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 1
  },
  exiting: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 1
  }
} as any;

type Props = {
  timeout: number;
  children: React.ReactNode;
  location: any;
};
type State = {
  offset: number;
  color: number;
};

class Transition extends React.Component<Props, State> {
  private switches: any;
  private scrollListener: any;
  private resizeListener: any;
  constructor(props: Props) {
    super(props);
    this.switches = {};
    this.state = {
      offset: 0,
      color: 0
    };
  }
  componentDidMount() {
    this.setSwitches();
    this.resizeListener = document.addEventListener(
      "resize",
      () => utils.throttle(this.setSwitches(), 500),
      false
    );
    this.scrollListener = document.addEventListener(
      "scroll",
      () => utils.throttle(this.setMenu(), 100),
      false
    );
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollListener);
    window.removeEventListener("resize", this.resizeListener);
  }

  private handleExiting = () => {
    // anime({
    //   targets: `.bgA`,
    //   height: [`${100}%`, `${0}%`],
    //   delay: 0,
    //   opacity:[1,0],
    //   duration: 500,
    //   easing: "easeInOutCirc"
    // });
  };

  render() {
    const { children, location } = this.props;
    const { color } = this.state;
    return (
      <>
        <TransitionGroup>
          <ReactTransition
            onExiting={this.handleExiting}
            key={location.pathname}
            mountOnEnter={true}
            timeout={{
              enter: timeout,
              exit: timeout
            }}
          >
            {(status: any) => (
              <>
                <div
                  style={{
                    ...getTransitionStyles[status]
                  }}
                >
                  {children}
                </div>
              </>
            )}
          </ReactTransition>
          <Menu mode={String(color) || "light"} path={location.pathname} />
        </TransitionGroup>
      </>
    );
  }

  private setMenu = () => {
    const y = window.pageYOffset;
    const num = utils.closestNumber(Object.keys(this.switches), y);
    if (num !== this.state.offset) {
      this.setState({ offset: num, color: this.switches[num] });
    }
  };

  private setSwitches = () => {
    const all = document.querySelectorAll(".section") as NodeListOf<
      HTMLElement
    >;
    all.forEach(el => {
      const recs = el.getBoundingClientRect();
      this.switches = {
        ...this.switches,
        [Math.floor(recs.top)]: el.dataset["color"],
        [Math.floor(recs.bottom - 1)]: el.dataset["color"]
      };
    });
  };
}

export default Transition;
