export type HeaderProps = { title: string; color: string; to: string };
const socialImg = require("../assets/images/pg_social.jpg");
const pitch_a = require("../assets/images/pitch_night_1.jpg");
const pitch_b = require("../assets/images/pitch_night_2.jpg");

const team_hadino = require("../assets/images/team_hadino.jpg");
const team_hadino_avatar = require("../assets/images/team_hadino_avatar.jpg");
const team_kunga_avatar = require("../assets/images/team_kunga_avatar.jpg");
const team_meyer_avatar = require("../assets/images/team_meyer_avatar.jpg");
const team_cecilia_avatar = require("../assets/images/team_cecilia_avatar.jpg");
const team_cover = require("../assets/images/team_cover.jpg");

const value_vision = require("../assets/images/value_vision.jpg");
const value_mission = require("../assets/images/value_mission.jpg");
const value_philosophy = require("../assets/images/value_philosophy.jpg");

const gen_img = require("../assets/images/case_gen_a.jpg");
const lucidity_img = require("../assets/images/case_lucidity_a.jpg");
const fashion_img = require("../assets/images/case_fashion.jpg");

// CLIENT ICONS
const clients_bon = require("../assets/images/clients_bon.png");
const clients_bw = require("../assets/images/clients_bw.png");
const clients_cr = require("../assets/images/clients_cr.png");
const clients_eldc = require("../assets/images/clients_eldc.png");
const clients_en = require("../assets/images/clients_en.png");
const clients_fnb = require("../assets/images/clients_fnb.png");
const clients_mva = require("../assets/images/clients_mva.png");
const clients_nust = require("../assets/images/clients_nust.png");
const clients_sanlam = require("../assets/images/clients_sanlam.png");
const clients_std = require("../assets/images/clients_std.png");
const clients_unam = require("../assets/images/clients_unam.png");
const clients_gen = require("../assets/images/clients_gen.png");

const clients_bon_w = require("../assets/images/clients_bon_w.png");
const clients_bw_w = require("../assets/images/clients_bw_w.png");
const clients_cr_w = require("../assets/images/clients_cr_w.png");
const clients_eldc_w = require("../assets/images/clients_eldc_w.png");
const clients_en_w = require("../assets/images/clients_en_w.png");
const clients_fnb_w = require("../assets/images/clients_fnb_w.png");
const clients_mva_w = require("../assets/images/clients_mva_w.png");
const clients_nust_w = require("../assets/images/clients_nust_w.png");
const clients_sanlam_w = require("../assets/images/clients_sanlam_w.png");
const clients_std_w = require("../assets/images/clients_std_w.png");
const clients_unam_w = require("../assets/images/clients_unam_w.png");
const clients_gen_w = require("../assets/images/clients_gen_w.png");

const avolites = require("../assets/images/brand_avolites.png");
const chauvet = require("../assets/images/brand_chauvet.png");
const christie = require("../assets/images/brand_christie.png");
const euro = require("../assets/images/brand_euro.png");
const magic = require("../assets/images/brand_magic.png");
const robe = require("../assets/images/brand_robe.png");
const sony = require("../assets/images/brand_sony.png");
const stage = require("../assets/images/brand_stage.png");
const turbo = require("../assets/images/brand_turbo.png");
const yamaha = require("../assets/images/brand_yamaha.png");
const sam = require("../assets/images/brand_sam.png");
const apple = require("../assets/images/brand_apple.png");
const db = require("../assets/images/brand_db.png");
const sennheiser = require("../assets/images/brand_sen.png");
const shure = require("../assets/images/brand_shure.png");

const float_a = require("../assets/images/floats_a.jpg");
const float_b = require("../assets/images/floats_b.jpg");
const float_c = require("../assets/images/floats_c.jpg");
const float_d = require("../assets/images/floats_d.jpg");
const float_e = require("../assets/images/floats_e.jpg");

const hero_a = require("../assets/images/hero/hero_a-min.jpg");
const hero_b = require("../assets/images/hero/hero_b-min.jpg");
const hero_c = require("../assets/images/hero/hero_c-min.jpg");
const hero_d = require("../assets/images/hero/hero_d-min.jpg");
const hero_e = require("../assets/images/hero/hero_e-min.jpg");
const hero_f = require("../assets/images/hero/hero_f-min.jpg");
const hero_g = require("../assets/images/hero/hero_g-min.jpg");
const hero_h = require("../assets/images/hero/hero_h-min.jpg");
const hero_i = require("../assets/images/hero/hero_i-min.jpg");

export const SITE = {
  sections: {
    equipment: "A small glimpse of our tech, gear and systems.",
    about: "Get to know all about us, our team and values."
  }
};

export type Art = {
  src: string;
  info?: string;
  client?: string;
};
export const ARTWORKS: Art[] = [
  { src: hero_a },
  { src: hero_b },
  { src: hero_c },
  { src: hero_d },
  { src: hero_e },
  { src: hero_f },
  { src: hero_g },
  { src: hero_h },
  { src: hero_i }
];

export const MENU = {
  direct: [
    // { title: "Book Now", to: "/quote" },
    // { title: "Call 0800 088 088 ", to: "/" }
  ],
  social: [
    {
      title: "Instagram",
      to: "https://www.instagram.com/ubuntu_events_technology/",
      tag: "Check our insta"
    },
    {
      title: "Twitter",
      to: "https://twitter.com/Ubuntu_Events",
      tag: "Follow us"
    },
    {
      title: "Mail",
      to: "mailto:hello@ubuntuevents.co",
      tag: "Contact us"
    }
  ],
  links: [
    { title: "About us", to: "/about", tag: "Get to know us" },
    { title: "Get a Quote", to: "/quote", tag: "Quick and easy" },
    { title: "Our equipment", to: "/equipment", tag: "Check out our gear" },
    {
      title: "Social Responsibility",
      to: "/csr",
      tag: "Giving back is part of our success"
    },
    {
      title: "Privacy Policy",
      to: "/privacy",
      tag: "Policy & Terms"
    }
  ]
};

export type Client = {
  light: string;
  dark: string;
  name: string;
};

export const CLIENTS: Client[] = [
  {
    light: clients_bon_w,
    dark: clients_bon,
    name: ""
  },
  {
    light: clients_bw_w,
    dark: clients_bw,
    name: ""
  },
  {
    light: clients_cr_w,
    dark: clients_cr,
    name: ""
  },
  {
    light: clients_eldc_w,
    dark: clients_eldc,
    name: ""
  },
  {
    light: clients_en_w,
    dark: clients_en,
    name: ""
  },
  {
    light: clients_fnb_w,
    dark: clients_fnb,
    name: ""
  },
  {
    light: clients_mva_w,
    dark: clients_mva,
    name: ""
  },
  {
    light: clients_nust_w,
    dark: clients_nust,
    name: ""
  },
  {
    light: clients_sanlam_w,
    dark: clients_sanlam,
    name: ""
  },
  {
    light: clients_std_w,
    dark: clients_std,
    name: ""
  },
  {
    light: clients_unam_w,
    dark: clients_unam,
    name: ""
  },
  {
    light: clients_gen_w,
    dark: clients_gen,
    name: ""
  }
];

export const header = [
  { title: "Sound", color: "#faebd7", to: "/" },
  { title: "Stage", color: "#d8eaee", to: "/" },
  { title: "Lighting", color: "#f5ced5", to: "/" },
  {
    title: "Audio Visuals",
    color: "#f78ea6",
    to: "/",
    list: ["Conferencing", "Sales and Installations"]
  }
];

export const services = [
  {
    title: "sound"
  },
  {
    title: "stage"
  },
  {
    title: "lighting"
  },
  {
    title: "audio-visuals"
  },
  {
    title: "conferencing"
  },
  {
    title: "sales-and-installations"
  }
];

export const CSR = {
  headerImage: socialImg,
  csr_main: {
    title: "Pitch Night",
    subtitle: `An initiative that we sponsors (Sound and Staging) for
  business people from all walks of life especially young
  entrepreneurs to be able to present their business ideas to
  potential investors in an attempt for propel entrepreneurship
  in our communities.`,
    text: `Pitch Night is an initiative by Launch Media Consultants. It
  runs parallel to the “Launch Namibia” initiative, a model
  that strives towards creating an entrepreneurial ecosystem.
  The idea is to have Pitch Night every first Wednesday of
  every new month.`,
    images: [
      {
        title: "pitch night image a",
        src: pitch_a
      },
      {
        title: "pitch night image b",
        src: pitch_b
      }
    ],
    links: [
      {
        to: "http://www.launch.com.na/pitch-night/",
        name: "launch.com"
      },
      {
        to: "mailto:hello@launch.com.na",
        name: "hello@launch.com.na"
      }
    ]
  },
  csr_a: {
    title: "#FuckUp night",
    subtitle: `Young successful business people from all walks of life congregate to relive the moments that made them succeed. Each speaker is asked to share their experience of failures when they first ventured into business. Ubuntu has recently thrown its weight behind this fantastic initiative.`
  },
  csr_b: {
    title: "Poiya Media",
    subtitle: `A newly established PR firm that is making waves in the Namibian corporate industry. Ubuntu is a staunch supporter of Poiya media’s various initiatives.`
  },
  csr_c: {
    title: "High School Events",
    subtitle: `Many public schools cannot afford quality PA systems for their special events. This is where Ubuntu gets involved by offering its services in order to ensure that pupils get a taste of what real memories should sound and look like.`
  }
};

export type Team = {
  avatar: string;
  image: string;
  name: string;
  title: string;
  description: string;
};

export const TEAM = {
  title: "Our team",
  subtitle: "we are dedicated, enthusiastic and passionate about our craft",
  cover: team_cover,
  members: [
    {
      avatar: team_hadino_avatar,
      image: team_hadino,
      name: "Hadino",
      title: "Team Leader",
      description: `Nothing escapes his keen eye. A scientist by training, Hadino’s education was defeated by his love and passion for events. He boasts more than 8 years’ experience in the entertainment and events industry.`
    },
    {
      avatar: "",
      image: "",
      name: "Amanda",
      title: "Client Services Administrator",
      description: `A business management student, Amanda puts theory into practice to ensure that the company’s bottom line is not overrun by passion and enthusiasm.`
    },
    {
      avatar: team_cecilia_avatar,
      image: team_cecilia_avatar,
      title: `Marketing Officer`,
      name: `Cecilia`,
      description: `A butterfly with a huge fan base on social media, Cecilia worked in the marketing promotions industry for over 4 years before joining Ubuntu. She leads brand management and advertising.`
    },
    {
      avatar: team_kunga_avatar,
      image: team_kunga_avatar,
      name: "Fernando",
      title: "Logistics Coordinator ",
      description: `Always on time, this thoroughbred entrepreneur has run several businesses of his own. He boasts experience in export and mining where he managed logistics for over half a decade before making the jump to coordinating Ubuntu’s logistics.`
    },
    {
      avatar: team_meyer_avatar,
      image: team_meyer_avatar,
      name: `Meyer`,
      title: "Sound Engineer",
      description: `A seasoned sound engineer that is highly knowledgeable on conferencing systems, large outdoor PA systems and system installations. Polleny has worked for most companies in this sector for over 10 years before finding a home at Ubuntu.`
    },
    {
      avatar: "",
      image: "",
      name: `Francis`,
      title: `Audio Visual Technician`,
      description: `A computer scientist by training, Francis joined the industry two years ago by sheer coincidence, when his friend, a lighting technician asked him to help- out at a gig. He assisted by setting up an AV system, and never stopped ever since. `
    },
    {
      avatar: "",
      image: "",
      name: `Angula`,
      title: `Staging`,
      description: `Born and bred in northern Namibia, Angula came to Windhoek in search of greener pastures. After doing a stint for 8 months as a warehouse labourer, he resolved that his handy-man skills are better used for creating stages. He has been with Ubuntu for more than one year and counting.`
    },
    {
      avatar: "",
      image: "",
      name: `Sakaria `,
      title: `Rigging`,
      description: `A man of a few words, but his work speaks volumes. Sakaria worked in the construction industry for over 3 years before he was snatched up by Ubuntu events to execute rigging.`
    },
    {
      avatar: "",
      image: "",
      title: `Lighting Technician`,
      name: `Attie`,
      description: `An IT enthusiast turn lighting technician, Attie has worked with lights for over 5 years.`
    },
    {
      avatar: "",
      image: "",
      title: `Disc Jockey`,
      name: `Aaron`,
      description: `A third-year science student with a passion for rhythm, sound and the allure of the nightlife. Aaron has been spinning decks since he had fingers, he joined the Ubuntu family three years ago.`
    }
  ] as Team[]
};

export type Service = {
  icon: React.ReactElement | null;
  id: string;
  color: string;
  title: string;
  image: string | null;
  info: string;
  text: string;
};
export const SERVICES = [
  {
    id: "f_sound",
    icon: null,
    color: "#d36ec6",
    title: "Sound",
    text: "Entertain and be heard",
    info: `At the heart of all events is the need to communicate effectively and to be heard clearly, for this reason, Ubuntu does not negotiate on sound quality. 
    We use only the finest brands on the market. From small corporate functions to big international conferences with simultaneous translations, 
    our sound engineers pride themselves on providing the highest quality of auditory services. `,
    image: null
  },
  {
    id: "f_stage",
    color: "#ff6382",
    icon: null,
    title: "Stage",
    text: "Set the stage alight",
    info: `From simple, to sophisticated stage requirements, our team of stage designers have years of industry experience and  consistently deliver unique, safe and spacious staging environments.  
    Our go to stage decks are  constructed from 21mm specially sourced European Birch plywood, with painted topping and assembled using light weight structural grade (6082-T6) aluminum extrusions. These decks are evaluated by  TUV NORD and approved for a load not exceeding 750kg per square metre.`,
    image: null
  },
  {
    id: "f_lighting",
    color: "#9085da",
    icon: null,
    title: "Lighting",
    text: "Enjoy the limelight",
    info: `Creating the right ambience is a daunting task. The misconception is that good lighting solely depends on advanced technology. The truth is, setting the right mood requires light operators who have a deep and uncanny understanding of the effects of hue, colour, and saturation on the human brain. Our team of technicians and artists are seasoned ambience designers who are constantly working on something new.`,

    image: null
  },
  {
    id: "f_av",
    color: "#38d996",
    icon: null,
    title: "Audio Visuals",
    text: "Add a touch of magic",
    info: `In an age of mass media and short attention span, video content has become the secret weapon in the arsenal of communication. Ubuntu specializes in a wide array of visual solutions, from high resolution screens for intimate enclosed boardroom events, to medium sized projections, and even large-scale outdoor daylight displays using LED and LCD technology.`,
    image: null
  },
  {
    id: "f_conf",
    color: "#4fadff",
    icon: null,
    title: "Conferencing",
    text: "We ensure engagement",
    info: `Ubuntu has successfully managed various large and small scale conferences. This service typically consists of a combination of providing simultaneous interpretation booths, screens, live streaming service, and equipping breakway rooms.`,
    image: null
  },
  {
    id: "f_sandi",
    color: "#ffab9d",
    icon: null,
    title: "Sales & Installations",
    info: `Ubuntu's seasoned sound engineers are able procure and install permanent sound system into your building. They take great care to ensure that the installation is easy to use and does not interfere pre-existing functions of the building.`,
    text: "Tested and trusted",
    image: null
  }
] as Service[];

export type Expertise = {
  label: string;
  title: string;
  info: string;
  img: string;
};

export const EXPERTISE = [
  {
    label: "Engaging",
    title: "Conferences & Meetings",
    info: ``,
    img: hero_a
  },
  {
    label: "Energized",
    title: "Sporting & Corporate events",
    info: ``,
    img: hero_b
  },
  {
    label: "Inspired",
    title: "Product launches",
    info: ``,
    img: hero_c
  },
  {
    label: "Emaculate",
    title: "Weddings & receptions",
    info: ``,
    img: hero_d
  },
  {
    label: "Spectacular",
    title: "Award ceremonies",
    info: ``,
    img: hero_e
  },
  {
    label: "Passionate about",
    title: "All your events",
    info: ``,
    img: hero_f
  }
] as Expertise[];

export type Value = {
  id: string;
  title: string;
  tag: string;
  image: string;
  icon: React.ReactElement | null;
  text: string;
};

export const VALUES = [
  {
    id: "value_0",
    title: "Philosophy",
    tag: "what we live by",
    icon: null,
    image: value_philosophy,
    text: `We believe it all begins with one important philosophy, a collaborative spirit. We pride ourselves on a flexible and creative approach to creating sensible solutions for our clients. We stand ready to execute changes smoothly, quickly and decisively.
      We are highly aware that the success of your event is based on the audience’s collective experience, hence we pledge to focus our efforts on how that experience will look, sound and feel from the attendees' point.`
  },
  {
    id: "value_1",
    title: "Vision",
    tag: "where we are going",
    icon: null,
    image: value_vision,
    text:
      "To be the most versatile and innovative events production company in Namibia, with a reputation of promising quality and delivering."
  },
  {
    id: "value_2",
    title: "Mission",
    tag: "what we will accomplish",
    icon: null,
    image: value_mission,
    text:
      "Our mission is to redefine the limitations of our industry and strive to be the leading company in the event production sector in Namibia."
  }
] as Value[];

export type Review = {
  name: string;
  title: string;
  text: string;
  img: string;
};

export const FLOATS = [float_a, float_b];

export const REVIEWS = [
  {
    name: "Ntwala Mwilima",
    img: float_a,
    title: "GM: Corporate affairs & Strategy NamibRe",
    text:
      "We remain thankful for the efficient service delivery and quality of work provided to us by ubuntu events."
  },
  {
    name: "Teofilus Nghitila",
    img: float_b,
    title: "Environmental Commissioner",
    text:
      "Their performance excelled in all categories including customer satisfaction, adherence to schedule, quality of equipment and workmanship."
  },
  {
    name: "Isaak Narib",
    img: float_c,
    title: "Standard Bank Namibia",
    text:
      "Highly Skilled, easy to work with and always willing to take time to discuss our concerns and respond to questions."
  },
  {
    name: "Paul Newman",
    img: float_d,
    title: "Lucidity Technical Production Services South Africa",
    text:
      "We will certainly use them again I future and recommend their services."
  },
  {
    name: "Maggy Mbako",
    img: float_e,
    title: "PRO Namibia Tourism Board",
    text:
      "I want to compliment Ubuntu Events Technology for their consistency, quality of work and contribution to our organization"
  }
] as Review[];

export type CaseService = {
  title: string;
  image?: string;
  gear: string[];
};

export type Showcase = {
  id: number;
  event: string;
  client?: string;
  location?: string;
  image: string;
  services: CaseService[];
};

export const SHOWCASES = [
  {
    id: 0,
    event: "Namibia Business \n Hall of Fame Awards",
    location: "Safari Court and Conference",
    image: gen_img,
    services: [
      {
        title: "Sound",
        image: "",
        gear: [
          "TurboSound Milan MiO Self Powered Speakers",
          "Delay Unit Shark DSP 110",
          "ProD2 DI Box"
        ]
      },
      {
        title: "Lighting",
        image: "",
        gear: [
          "Robe LED 600 Wash moving head fixture",
          "Avolites Titan One Lights Controller0",
          "Rama Fresnel 1200w White Light"
        ]
      },
      {
        title: "Audio Visuals",
        image: "",
        gear: [
          "Christie LX605 LCD projector 1.70-2.89:1",
          "Rear Surface F/F /Da-lite / 3.05m x 2.3m (7.6x10) Projector",
          "Kramer VP-729 / 9-Input / Presentation Scaler"
        ]
      },
      {
        title: "Stage",
        image: "",
        gear: ["Nivtec Flexible 2m x 1m Deck"]
      }
    ]
  },
  {
    id: 1,
    event: "Salshi Designs Fashion Show",
    client: "Salmi Shilongo (Fashion Designer)",
    image: fashion_img,
    services: [
      {
        title: "Sound",
        image: "",
        gear: [
          "TurboSound Milan MiO Self Powered Speakers",
          "Sennheiser SKM 100-945 wire-less microphone",
          "Delay Unit Shark DSP 110"
        ]
      },
      {
        title: "Lighting",
        image: "",
        gear: [
          "Rama Fresnel 1200w White Light",
          "Avolites Titan One Lights Controller",
          "Rama Fresnel 1200w White Light"
        ]
      },
      {
        title: "Audio Visuals",
        image: "",
        gear: [
          'Sony Bravia 46" LED Display / LED EX65 Preview Monitor',
          'Proline 55" LED Display FHD',
          "BlackMagic ATEM Television Studio 4K",
          "Sony HVRHD1000U video camera"
        ]
      }
    ]
  },
  {
    id: 2,
    event: "Total South Africa- Startupper of The Year (2015)",
    client: "Lucidity Technical Productions (South Africa)",
    image: lucidity_img,
    services: [
      {
        title: "Sound",
        image: "",
        gear: [
          "Yamaha LS9/16 DIGITAL Mixing Console",
          "TurboSound Milan MiO",
          "Sennheiser ME4-N cardoid lapel microphone",
          "Sennheiser SKM 100-945 wire-less microphone",
          "CD Player single / Gemini CDMP-1400"
        ]
      },
      {
        title: "Lighting",
        image: "",
        gear: [
          "ETC Source Four Zoom Profile spot / 25-50deg",
          "Chauvet SlimPar Tri7",
          "Phillips/Strand - Dim1 - Single phase Dimmer"
        ]
      },
      {
        title: "Audio Visuals",
        image: "",
        gear: [
          "Christie LX605 LCD projector 1.70-2.89:1",
          "Rear Surface F/F /Da-lite / 3.05m x 2.3m (7.6x10) Projector Screen",
          "Kramer VP-729 / 9-Input / Presentation Scaler",
          "BlackMagic / SDI-HDMI Mini Converter"
        ]
      },
      {
        title: "Stage",
        image: "",
        gear: ["Nivtec Flexible 2m x 1m Deck"]
      }
    ]
  }
] as Showcase[];

export type Brand = {
  title: string;
  src?: string;
};

export const GEAR = {
  tech: [
    "P.A System",
    "Headset Microphones",
    "Lapel Mircophones",
    "Digital Mixing Consoles",
    "Smoke Machines",
    "Podiums",
    "Projectors and Projector Screens",
    "LED Moving Head lights",
    "Trussing",
    "Staging",
    "Drape",
    "Power",
    "Video Cameras"
  ],
  brands: [
    { title: "avolites", src: avolites },
    { title: "chauvet", src: chauvet },
    { title: "christie", src: christie },
    { title: "eurotruss", src: euro },
    { title: "sennheiser", src: sennheiser },
    { title: "black magic design", src: magic },
    { title: "robe", src: robe },
    { title: "sony", src: sony },
    { title: "stage", src: stage },
    { title: "turbo", src: turbo },
    { title: "shure", src: shure },
    { title: "yamaha", src: yamaha },
    { title: "samsung", src: sam },
    { title: "apple", src: apple },
    { title: "db technologies", src: db }
  ]
};
