import { is } from "ramda";
import * as React from "react";
import {
  useMeasure,
  useWindowSize,
  useToggle,
  useWindowScroll,
  useMedia
} from "react-use";
import anime from "animejs";
import { X, Menu as MenuIcon, Twitter, Instagram, Mail } from "react-feather";
const Logo = require("../../assets/svg/logo_color.svg");
const LogoText = require("../../assets/svg/logotext.svg");
import * as utils from "../utils";

import "./menu.scss";
import { MENU, MenuLink } from "../../content";

type Props = {
  links?: MenuLink[];
  direct?: MenuLink[];
  social?: MenuLink[];
  path: string;
  mode: string;
};

export const Menu: React.FunctionComponent<Props> = props => {
  const { y } = useWindowScroll();
  const [isOpen, setIsOpen] = useToggle(false);
  const [refB, refBObj] = useMeasure();
  const [refA, refAObj] = useMeasure();
  const { height } = useWindowSize();
  const offset = React.useRef(0);
  const refMain: { current: undefined | any } = React.useRef(undefined);
  const refMenu: { current: undefined | any } = React.useRef(undefined);
  const refBGA: { current: undefined | any } = React.useRef(undefined);
  const refBGB: { current: undefined | any } = React.useRef(undefined);
  const isMobile = useMedia("(max-width: 768px)");

  const { path, mode, links, social } = { ...props, ...MENU };
  const isHome = path === "/";
  const isAbout = path.indexOf("/about") !== -1;
  const isQuote = path.indexOf("/quote") !== -1;
  // const isMobile = width < 768;

  const getSocialIcon = (name: string) => {
    switch (name.toLocaleLowerCase()) {
      case "twitter":
        return <Twitter />;
      case "mail":
        return <Mail />;
      default:
        return <Instagram />;
    }
  };

  const handleNavigate = (to: string) => {
    if (!isOpen) {
      utils.navTo(to);
    } else {
      return handleToggle(() => utils.navTo(to));
    }
  };

  const toggle = utils.throttle(() => {
    handleToggle();
  }, 1000);

  const handleToggle = (cb?: () => void) => {
    let wHeight = height * 0.9;
    //

    const content = refAObj.height + refBObj.height + 100 || 0;
    const minHeight = !isOpen ? content : 0;

    if (wHeight < minHeight) {
      wHeight = minHeight;
    }

    if (!isOpen) {
      anime({
        targets: [".mainMenu"],
        duration: 10,
        height: `${100}%`,
        easing: "easeInOutQuad",
        complete: () => {
          anime({
            targets: [".mainMenu__bgA", ".mainMenu__bgB", ".mainMenu__content"],
            duration: 400,
            delay: anime.stagger(100),
            height: `${wHeight}px`,
            easing: "easeInOutQuad"
          });

          anime({
            targets: [".mainMenu__content-link p"],
            delay: anime.stagger(100),
            translateY: [30, 0],
            opacity: [0, 0.5],
            duration: 600,
            easing: "easeInOutQuad"
          });

          anime({
            targets: [".mainMenu__content-social"],
            delay: 400,
            duration: 200,
            opacity: [0, 1],
            easing: "easeInOutQuad"
          });

          anime({
            targets: [".mainMenu"],
            delay: 100,
            duration: 400,
            backgroundColor: "rgba(0, 0, 0, .2)",
            easing: "easeInOutQuad"
          });
        }
      });
    } else {
      if (document) {
        document.body.className = document.body.className.replace(
          "noscroll",
          ""
        );
      }

      anime({
        targets: [".mainMenu__content-link p"],
        duration: 200,
        translateY: [0, 30],
        opacity: [0.5, 0],
        easing: "easeInOutQuad"
      });
      anime({
        targets: [".mainMenu__content-social"],
        duration: 50,
        delay: 0,
        opacity: [1, 0],
        easing: "easeInOutQuad"
      });
      anime({
        targets: [".mainMenu"],
        duration: 500,
        backgroundColor: "rgba(0, 0, 0, 0)",
        easing: "easeInOutQuad"
      });
      anime({
        targets: [".mainMenu__bgA", ".mainMenu__bgB", ".mainMenu__content"],
        duration: 400,
        delay: anime.stagger(100),
        height: [`${wHeight}px`, , `${0}px`],
        easing: "easeInOutQuad",
        complete: () => {
          anime({
            targets: [".mainMenu"],
            duration: 10,
            height: `${4}rem`,
            easing: "easeInOutQuad"
          });
          if (cb && is(Function, cb)) {
            cb();
          }
        }
      });
    }
    setIsOpen(!isOpen);
  };

  React.useEffect(() => {
    const atop = y > 56;
    const above = y < 56;
    const upward = y < offset.current;
    const active = above || upward;

    const el = refMain.current;
    if (el && el.classList) {
      if (active) {
        el.classList.remove("active");
      } else {
        el.classList.add("active");
      }
      if (atop) {
        el.classList.remove("smoke");
      } else {
        el.classList.add("smoke");
      }
    }
    offset.current = y;
  }, [y]);

  return (
    <header
      ref={refMain}
      className={`mainMenu ${isHome ? "home" : ""} ${
        isMobile ? "mobile" : ""
      } ${isOpen ? "open" : ""}`}
      id="mainMenu"
    >
      <nav ref={refMenu} className={`mainMenu__menu ${isHome ? "" : "isHome"}`}>
        <div className="container wrap">
          <button
            onClick={handleNavigate.bind(null, "/")}
            className="mainMenu__menu-home"
          >
            <Logo className="mainMenu__menu-home-logo" height="50" />
            <LogoText className="mainMenu__menu-home-logotext" height="50" />
          </button>
          {!isMobile && !isOpen && (
            <div
              className="mainMenu__menu-vitals"
              style={{ opacity: y > 56 ? 0 : 1 }}
            >
              {!isQuote && (
                <button
                  onClick={handleNavigate.bind(null, "/quote")}
                  className="mainMenu__menu-vitals-link"
                >
                  Quote
                </button>
              )}
              {!isAbout && (
                <button
                  onClick={handleNavigate.bind(null, "/about")}
                  className="mainMenu__menu-vitals-link"
                >
                  About
                </button>
              )}
            </div>
          )}
          <button
            className={`mainMenu__menu-toggle ${isOpen ? "open" : ""}`}
            onClick={toggle.bind(null, 70)}
          >
            <X className={`close ${isOpen ? "active" : ""}`} size={28} />
            <MenuIcon className={`open ${isOpen ? "" : "active"}`} />
          </button>
          {/* <div className={`mainMenu__menu-mid ${isOpen ? "faded" : ""}`}>
            {direct &&
              direct.map((e, i) => (
                <button
                  onClick={handleNavigate.bind(null, e.to)}
                  key={i}
                  className="mainMenu__menu-link"
                >
                  {e.title}
                </button>
              ))}
          </div> */}
        </div>
      </nav>

      <span ref={refBGA} className="mainMenu__bgA" />
      <span ref={refBGB} className="mainMenu__bgB" />
      <div className="mainMenu__content">
        <div ref={refA} className="container mainMenu__content-wrap clearfix">
          {links &&
            links.map((e, i) => (
              <button
                onClick={handleNavigate.bind(null, e.to)}
                key={i}
                className="mainMenu__content-link"
              >
                <span className="tag">#{e.tag}</span>
                <p className="h2">{e.title}</p>
              </button>
            ))}
        </div>
        <div ref={refB} className="mainMenu__socials">
          <div className="container">
            {social &&
              social.map((e, i) => (
                <a
                  target="blank"
                  href={e.to}
                  key={i}
                  className="mainMenu__content-social"
                >
                  {getSocialIcon(e.title)}
                </a>
              ))}
          </div>
        </div>
      </div>
    </header>
  );
};
