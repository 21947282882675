import * as React from "react";
import Helmet from "react-helmet";
import anime from "animejs";
import Transition from "./transitions";
import { helmet } from "../../utils/helmet";
const Logo = require("../../assets/svg/logo_color.svg");

import "../../styles/fonts.css";
import "./layout.scss";
import "../../styles/grid.scss";

type Props = {
  children: React.ReactNode;
  location: any;
};
type State = {
  isLoaded: boolean;
};

export default class Layout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isLoaded: false };
  }

  public componentDidMount() {
    this.removeLoader();
  }
  componentWillReceiveProps() {
    if (!this.state.isLoaded) {
      this.removeLoader();
    }
  }

  public removeLoader = () => {
    setTimeout(() => {
      if (document) {
        anime({
          targets: "#___loader",
          opacity: 0,
          duration: 300,
          height: `${0}px`,
          easing: "linear",
          complete: () => {
            this.setState({ isLoaded: true });
            document.body.classList.remove("nosscroll");
          }
        });
      }
    }, 1000);
  };

  public render() {
    return (
      <Transition location={this.props.location} timeout={5000}>
        <>
          <Helmet {...helmet}>
            <link
              href="https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,700,"
              rel="stylesheet"
            />
            <link
              href="https://fonts.googleapis.com/css?family=Merriweather:300,400,400i,700,700i&display=swap"
              rel="stylesheet"
            />
          </Helmet>
          <div id="bgA" className="bgA" />

          {this.props.children}
          {!this.state.isLoaded && (
            <div
              key="loader"
              id="___loader"
              style={{
                pointerEvents: "none",
                alignItems: "center",
                overflow: "hidden",
                backgroundColor: "#F2F2F2",
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                zIndex: 100
              }}
            >
              <Logo width="50" />
              <svg
                width="105"
                height="105"
                viewBox="0 0 105 105"
                xmlns="http://www.w3.org/2000/svg"
                fill="#e91e63"
                style={{ transform: "scale(.3)" }}
              >
                <circle cx="12.5" cy="12.5" r="12.5">
                  <animate
                    attributeName="fillOpacity"
                    begin="0s"
                    dur="1s"
                    values="1;.2;1"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                </circle>
                <circle cx="12.5" cy="52.5" r="12.5" fillOpacity=".5">
                  <animate
                    attributeName="fillOpacity"
                    begin="100ms"
                    dur="1s"
                    values="1;.2;1"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                </circle>
                <circle cx="52.5" cy="12.5" r="12.5">
                  <animate
                    attributeName="fillOpacity"
                    begin="300ms"
                    dur="1s"
                    values="1;.2;1"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                </circle>
                <circle cx="52.5" cy="52.5" r="12.5">
                  <animate
                    attributeName="fillOpacity"
                    begin="600ms"
                    dur="1s"
                    values="1;.2;1"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                </circle>
                <circle cx="92.5" cy="12.5" r="12.5">
                  <animate
                    attributeName="fillOpacity"
                    begin="800ms"
                    dur="1s"
                    values="1;.2;1"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                </circle>
                <circle cx="92.5" cy="52.5" r="12.5">
                  <animate
                    attributeName="fillOpacity"
                    begin="400ms"
                    dur="1s"
                    values="1;.2;1"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                </circle>
                <circle cx="12.5" cy="92.5" r="12.5">
                  <animate
                    attributeName="fillOpacity"
                    begin="700ms"
                    dur="1s"
                    values="1;.2;1"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                </circle>
                <circle cx="52.5" cy="92.5" r="12.5">
                  <animate
                    attributeName="fillOpacity"
                    begin="500ms"
                    dur="1s"
                    values="1;.2;1"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                </circle>
                <circle cx="92.5" cy="92.5" r="12.5">
                  <animate
                    attributeName="fillOpacity"
                    begin="200ms"
                    dur="1s"
                    values="1;.2;1"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                </circle>
              </svg>
            </div>
          )}
        </>
      </Transition>
    );
  }
}
