import { navigate } from "gatsby";
import anime from "animejs";

export const shuffle = (a: string[]) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};

export const getRandomColor = () => {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
// background-color: rgb(124, 126, 172);
export const navTo = (to: string) => {
  const el = document.getElementById("bgA") as any;
  Math.floor(Math.random() * 6) + 1;
  if (el) {
    el.classList.add("up");
  }
  anime({
    targets: `.bgA`,
    height: [`${0}%`, `${100}%`],
    delay: 50,
    opacity: [0, 1],
    duration: 500,
    easing: "easeInOutCirc",
    complete: () => {
      navigate(to);
      if (el) {
        el.classList.remove("up");
      }
      anime({
        targets: `.bgA`,
        height: [`${100}%`, `${0}%`],
        delay: 500,
        opacity: [1, 0],
        duration: 500,
        easing: "easeInOutCirc"
      });
    }
  });
};

export const timings = {
  open: 400
};

export const chunk = (arr: any[], size: number) => {
  const R = [];
  for (var i = 0; i < arr.length; i += size) R.push(arr.slice(i, i + size));
  return R;
};

export const getScreenSize = () => {
  const body = document.body;
  const html = document.documentElement;
  const height = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );
  const width = Math.max(
    body.scrollWidth,
    body.offsetWidth,
    html.clientWidth,
    html.scrollWidth,
    html.offsetWidth
  );
  return {
    height,
    width
  };
};

export const uuid = () =>
  Math.random()
    .toString(36)
    .substr(2, 9);
export const isMobile = () => {
  if (typeof window === "undefined") return;
  return getScreenSize().width < 567;
};
export const isTablet = () => {
  if (typeof window === "undefined") return;
  return getScreenSize().width > 567 && getScreenSize().width < 768;
};
export const sizeBelow = (s: number) => {
  if (typeof window === "undefined") return;
  return getScreenSize().width < s;
};
export const sizeAbove = (s: number) => {
  if (typeof window === "undefined") return;
  return getScreenSize().width > s;
};

export const trunc = function(str: string, n: number, e?: string) {
  return (
    str.substr(0, n - 1) + (str.length > n ? (e && e.length ? e : "...") : "")
  );
};

export const getElementHeights = (arr: string[]) => {
  if (typeof window === "undefined") return;
  let height = 0;
  arr.forEach(str => {
    const el: any = str.includes("#")
      ? document.getElementById(str)
      : document.querySelector(str);
    if (el) {
      height += el.offsetHeight;
    }
  });
  return height === 0 ? null : height;
};

export const getElementHeightsByAll = (arr: string[]) => {
  if (typeof window === "undefined") return;
  let height = 0;
  arr.forEach(str => {
    const el: any = document.querySelector(str);
    if (el) {
      height += el.offsetHeight;
    }
  });
  return height === 0 ? null : height;
};

export const getElementHeightsByClass = (cname: string) => {
  if (typeof window === "undefined") return;
  let height = 0;
  const arr = document.querySelectorAll(cname);
  arr.forEach((el: any) => {
    height += el.offsetHeight;
  });
  return height === 0 ? null : height;
};

export function throttle(fn: any, delay: number) {
  var timer: any;
  return function() {
    const last = timer;
    const now = Date.now();
    if (!last) {
      timer = now;
      // @ts-ignore
      fn.apply(this, arguments);
      return;
    }
    if (last + delay > now) return;
    timer = now;
    // @ts-ignore
    fn.apply(this, arguments);
  };
}

export const getViewport = () => {
  var viewPortWidth;
  var viewPortHeight;

  // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
  if (typeof window.innerWidth != "undefined") {
    (viewPortWidth = window.innerWidth), (viewPortHeight = window.innerHeight);
  }

  // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
  else if (
    typeof document.documentElement != "undefined" &&
    typeof document.documentElement.clientWidth != "undefined" &&
    document.documentElement.clientWidth != 0
  ) {
    (viewPortWidth = document.documentElement.clientWidth),
      (viewPortHeight = document.documentElement.clientHeight);
  }

  // older versions of IE
  else {
    (viewPortWidth = document.getElementsByTagName("body")[0].clientWidth),
      (viewPortHeight = document.getElementsByTagName("body")[0].clientHeight);
  }
  return { width: viewPortWidth, height: viewPortHeight };
};

export const setNav = (color: string) => {
  const event = new CustomEvent("navMode", { detail: color });
  document.dispatchEvent(event);
};

export const closestNumber = (a: string[], num: number) => {
  return (
    num -
    a.reduce(function(closest, v) {
      return num >= Number(v) ? Math.min(num - Number(v), closest) : closest;
    }, 1e100)
  );
};

export const ga = (params: any) => {
  if (typeof window !== "undefined") {
    if (window.ga) {
      window.ga(`send`, params);
    }
  }
};
